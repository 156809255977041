import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Lframe } from '@latitude/lframe';
import { DigitalWallets } from '@latitude/digital-wallets';
import { Hero } from '@latitude/hero';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Box } from '@latitude/box';
import { Heading4 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { ListNumbered } from '@latitude/list-numbered';
import ManageYourLoan from '@/components/ManageYourLoan/ManageYourLoan';
import Accordion from '@/components/Accordion/Accordion';
import {
  COLOR,
  BREAKPOINT,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import Layout from '../../components/layout';
import HardshipPageData from '../hardship-care/hardship.json';

const lfsAppData = [
  {
    title: 'App Store',
    href: 'https://apps.apple.com/app/apple-store/id1373059866',
    trackId: 'app-store',
    imageSrc: require('../../images/badge-app-store.svg').default,
    width: 128
  },
  {
    title: 'Google Play',
    href:
      'https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp',
    trackId: 'google-play',
    imageSrc: require('../../images/badge-google-play.svg').default,
    width: 128
  }
];

const Covid19AssistancePage = props => {
  return (
    <Layout location={props.location}>
      <Main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/campaigns/covid-19-ap"
          />
          <meta
            name="description"
            content="Latitude Financial Coronavirus Assistance Program is available. Here's a way we can help ease some of that financial stress for you."
          />
          <title>
            Latitude Financial COVID-19 Assistance Program | Latitude Financial
          </title>
        </Helmet>

        <HeroContainer>
          <Lframe bgImage={require('./images/l-shape-ap.png')} />
          <Hero
            title="Latitude Financial COVID-19 Assistance Program"
            text="Let's meet you halfway to ease the financial stress of COVID-19."
          />
        </HeroContainer>

        <StickyNavigation
          items={[
            {
              anchor: 'balance',
              label: 'Assistance program'
            },
            {
              anchor: 'need-to-know',
              label: 'What you need to know'
            },
            {
              anchor: 'assistance',
              label: 'Further assistance'
            },
            {
              anchor: 'account-details',
              label: 'Check your account'
            },
            {
              anchor: 'useful-information',
              label: 'Other information'
            }
          ]}
          offsetElem="[data-sticky-navigation-offset]"
        />

        <Box.Section id="balance" isBodyCopy backgroundColor={COLOR.GREY6}>
          <Text>
            We understand that the effects of COVID-19 are far reaching and may
            be making it hard
            <br />
            for you to manage your finances right now.
            <br />
            <br />
            Here&rsquo;s a way we can help ease some of that financial stress
            for you.
            <br />
          </Text>
          <Heading4 marginTop={MARGIN.M24}>
            If you pay 50% of your outstanding balance, we can take care of the
            rest
          </Heading4>
          <Text marginBottom={MARGIN.M24}>
            If you apply for our COVID-19 Assistance Program, all you need to do
            is pay
            <strong>
              {' '}
              50% of your balance by 4&nbsp;September&nbsp;2020 and we will pay
              the other 50% for you.
            </strong>{' '}
            You can make several payments or one lump sum payment in order to
            pay off 50% of your total outstanding balance, but it must be paid
            by 4&nbsp;September&nbsp;2020.
          </Text>
        </Box.Section>

        <HorizontalRule />

        <Box.Section id="need-to-know" isBodyCopy backgroundColor={COLOR.GREY6}>
          <Heading4 marginTop={MARGIN.M24}>What you need to know</Heading4>

          <ListNumbered
            counterBgColor="#0046AA"
            data={[
              'Applying for this Assistance Program means you are acknowledging you have been financially impacted by COVID-19.',
              'You will receive an email confirming that we have received your application when you reply &ldquo;Apply&rdquo; to the SMS. Please note it may take up to ten days for the changes to be reflected on your account once you have paid the required amount.',
              'Once you&rsquo;ve applied for this Assistance Program and made your payment, your account will be closed, and you will receive a confirmation email. This means you will no longer be able to make purchases using your card.',
              'The Assistance Program may be withdrawn if you do not accept and make payment of 50% of your outstanding total balance by 4 September 2020. Current payment and collections conditions will then continue to apply.'
            ]}
            separator="none"
          />
        </Box.Section>

        <HorizontalRule />

        <Box.Section id="assistance" isBodyCopy backgroundColor={COLOR.GREY6}>
          <Heading4 marginTop={MARGIN.M24}>Need further assistance?</Heading4>
          <Text marginBottom={MARGIN.M24}>
            Due to COVID-19 impacts, we&rsquo;re operating with significantly
            reduced capacity against high call volumes.  The fastest way to be
            served is to go online. Please use the following link to self-serve
            by logging{' '}
            <a href="https://latitudefs.zendesk.com/hc/en-au?gemid1=Mar20-CC-edm-CDM17396-COVID19-customer-comm&messageid=gemoney.56761382&receipientid=7c772b1420666193e848cbf8e18e264b">
              a general query
            </a>
            .
          </Text>
        </Box.Section>

        <HorizontalRule />

        <Box.Section id="assistance" isBodyCopy backgroundColor={COLOR.GREY6}>
          <Heading4 marginTop={MARGIN.M24}>We&rsquo;re here for you</Heading4>
          <Text marginBottom={MARGIN.M24}>
            As your partner in money, your financial wellbeing is important to
            us. That&rsquo;s why we&rsquo;ve put together a plan to help you
            during difficult times. If this is not the right financial
            assistance to support your current circumstances, you may want to
            consider alternative financial support{' '}
            <a href="https://www.latitudefinancial.com.au/hardship-care/">
              outlined here
            </a>
            .
          </Text>
        </Box.Section>

        <HorizontalRule />

        <Box
          css={`
            p {
              max-width: 630px; /* fixes orphan word */
            }
          `}
        >
          <DigitalWallets
            heading="Ways to check your account details"
            subheading="Manage your account with the Latitude App or by logging in to the Online Service Centre."
            data={lfsAppData}
            noShadow
          />
        </Box>

        <HorizontalRule />

        <UsefulInformationSection />
      </Main>

      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=34f4d43c-fb78-460c-bfa9-258bf81fcaca"
      />
    </Layout>
  );
};

export default Covid19AssistancePage;

const Main = styled.main`
  background-color: ${COLOR.GREY6};

  h4,
  p,
  .sticky-navigation__nav-item a .label {
    color: ${COLOR.BLACK};
  }
`;

const HeroContainer = styled.div`
  position: relative;
  z-index: 0;
  @media (min-width: ${BREAKPOINT.LG}) {
    .HeroContent {
      display: flex;
      align-items: center;
      min-height: 400px;
    }
  }
`;

const UsefulInformationSection = () => (
  <Box
    id="useful-information"
    css={`
      padding: 72px 0 !important;
      h5 {
        color: ${COLOR.BLACK};
        font-size: 20px;
      }
      h6 {
        font-size: 16px;
        margin: 16px 0 8px;
      }
      h6 + p {
        margin-top: 0;
      }
    `}
  >
    <ManageYourLoan
      heading={HardshipPageData.content.usefulInfo.title}
      description={HardshipPageData.content.usefulInfo.description}
    >
      <Accordion
        titleBgColor="grey-light"
        items={HardshipPageData.content.usefulInfo.accordionData.map(
          accordionDataItem => {
            return {
              id: accordionDataItem.id,
              title: accordionDataItem.title,
              content: (
                <Box padding={PADDING.P16}>
                  {ReactHtmlParser(accordionDataItem.data)}
                </Box>
              )
            };
          }
        )}
      />
    </ManageYourLoan>
  </Box>
);
